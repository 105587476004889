@font-face {
  font-family: 'Clash Display';
  src: url('../src/assets/Fonts/ClashDisplay-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
    font-display: swap;
      /* Ensures faster loading */
}

@font-face {
  font-family: 'Clash Display';
  src: url('../src/assets/Fonts/ClashDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
    font-display: swap;
      /* Ensures faster loading */
}

* {
  font-family: 'Clash Display', sans-serif,;
}