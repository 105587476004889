.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
  background-color: rgba(255, 255, 255, 0.8);
  /* Slight white overlay background */
}

.loader-container img {
  width: 200px;
  /* Adjust size as needed */
  height: 200px;
  /* Adjust size as needed */
  object-fit: contain;
}